import React, { useEffect } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { ResponsiveImage } from '../../../../components';
import heroImage from '../../../../assets/heroImage.png';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    alignment,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const alignmentClasses = {
    left: css.alignLeft,
    center: css.alignCenter,
    right: css.alignRight,
  };

  const alignmentClass = alignmentClasses[alignment];

  const firstCallToAction = {
    content: 'Browse properties',
    fieldType: 'externalButtonLink',
    href: '/s',
  };

  const secondCallToAction = {
    content: 'List your property',
    fieldType: 'externalButtonLink',
    href: '/l/new',
  };

  useEffect(() => {
    const changeH1ColorAndShowHeroTitle = () => {
      const heroContent = document.getElementById(blockId);

      if (heroContent && blockId === 'hero-content') {
        const heroTitle = heroContent.querySelector(`.${css.heroTitle}`);
        const customButton = heroContent.querySelector(`.${css.customButton}`);
        const heroImageStyle = heroContent.querySelector(`.${css.heroImage}`);
        const pElement = heroContent.querySelector(`p`);

        pElement.classList.add(css.highlightedParagraph);

        if (heroTitle) {
          heroTitle.style.display = 'block';
        }
        if (customButton) {
          customButton.style.display = 'flex';
        }
        if (heroImageStyle) {
          heroImageStyle.style.display = 'flex';
        }
      }
    };

    changeH1ColorAndShowHeroTitle(); // Call the function once on mount

    // Optionally, add more logic here if needed to trigger color change dynamically
  }, [blockId]); // No dependencies, runs once on mount

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />

      {hasTextComponentFields ? (
        <div className={classNames(textClassName, alignmentClass, css.text)}>
          <Field data={title} className={css.title} options={options} />
          <div className={css.heroTitle}>
            Buy & sell homes <span className={css.highlight}>without</span> agents or commission
            fees
          </div>
          <Field data={text} options={options} />
          <Field data={callToAction} className={ctaButtonClass} options={options} />
          <div className={css.customButton}>
            <div className={css.buttonContainer}>
          <Field data={firstCallToAction} className={css.firstCtaButton} options={options} />
          <Field data={secondCallToAction} className={css.secondCtaButton} options={options} />
          </div>
          </div>
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
